import React from 'react'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div className="flex flex-column justify-content-center h-100">
      <div className="has-text-centered">
        <h1 className="title">Bridgit Bowden</h1>
        <p className="subtitle">
          is a public radio journalist based in Wisconsin.
          <br />
          Follow her here: { ' ' }
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.twitter.com/bridgitbowden"
          >
            @BridgitBowden
          </a>
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
